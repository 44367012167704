import { Link } from '@remix-run/react';
import { Trans } from 'react-i18next';
import { $path } from 'remix-routes';
import { Button } from '~/components/ui/button';
import { ErrorPageTemplate } from './error-page-template';

const ns = 'page' satisfies Ns;
export const Unauthorized = () => (
  <ErrorPageTemplate
    title={
      <Trans ns={ns} i18nKey='401.title'>
        401
      </Trans>
    }
    description={
      <Trans ns={ns} i18nKey='401.description'>
        Unauthorized
      </Trans>
    }
    content={
      <Trans ns={ns} i18nKey='401.content'>
        Oops! It looks like you don’t have permission to access this page.
      </Trans>
    }
    footer={
      <Button variant='outline' asChild>
        <Link to={$path('/')} className='block w-full rounded px-4 py-2'>
          <Trans ns={ns} i18nKey='button.home'>
            Return to Home
          </Trans>
        </Link>
      </Button>
    }
  />
);
