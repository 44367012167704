import { useFetcher } from '@remix-run/react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

const prefersDarkMQ = '(prefers-color-scheme: dark)';
export const getPreferredTheme = () => (window.matchMedia(prefersDarkMQ).matches ? 'dark' : 'light');

type ThemeContextType = [Theme | null, HtmlTheme | null, Dispatch<SetStateAction<Theme | null>>];

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}

export type Theme = (typeof themes)[number];
export type HtmlTheme = Exclude<Theme, 'system'>;
const themes = ['dark', 'light', 'system'] as const;
export const themeValues = themes;

export function isTheme(value: unknown): value is Theme {
  return typeof value === 'string' && themes.includes(value as Theme);
}

export const ThemeProvider = ({ children, specifiedTheme }: { children: ReactNode; specifiedTheme: Theme }) => {
  const [theme, setThemeValue] = useState<Theme | null>(() => {
    if (themes.includes(specifiedTheme)) {
      return specifiedTheme;
    }

    // there's no way for us to know what the theme should be in this context
    // the client will have to figure it out before hydration.
    if (typeof window !== 'object') {
      return null;
    }

    return getPreferredTheme();
  });
  const fetcher = useFetcher();

  const setTheme: Dispatch<SetStateAction<Theme | null>> = useCallback(
    value => {
      const newTheme = typeof value === 'function' ? value(theme) : value;
      setThemeValue(newTheme);
      fetcher.submit({ theme: newTheme ?? 'system' }, { action: 'set-theme', method: 'post' });
    },
    [fetcher, theme]
  );

  const [className, refreshClassname] = useReducer<(prev: HtmlTheme | null) => HtmlTheme | null, Theme | null>(
    () => {
      if (theme !== 'system' && theme !== null) return theme;

      return getPreferredTheme();
    },
    theme ?? 'system',
    arg => {
      if (arg !== 'system' && arg !== null) return arg;

      // there's no way for us to know what the theme should be in this context
      // the client will have to figure it out before hydration.
      if (typeof window !== 'object') {
        return null;
      }

      return getPreferredTheme();
    }
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to refresh the classname when the theme changes
  useEffect(() => {
    refreshClassname();
  }, [theme]);

  useEffect(() => {
    const mediaQuery = window.matchMedia(prefersDarkMQ);
    const handleChange = () => {
      refreshClassname();
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return <ThemeContext.Provider value={[theme, className, setTheme]}>{children}</ThemeContext.Provider>;
};

// language=JavaScript
const clientThemeCode = `
  ;(() => {
    const theme = window.matchMedia(${JSON.stringify(prefersDarkMQ)}).matches
        ? 'dark'
        : 'light';
    const cl = document.documentElement.classList;
    const themeAlreadyApplied = cl.contains('light') || cl.contains('dark');
    if (themeAlreadyApplied) {
      // this script shouldn't exist if the theme is already applied!
      console.warn(
          "Hi there, this text shouldn't be visible for you",
      );
    } else {
      cl.add(theme);
    }
  })();
`;

export const NonFlashOfWrongThemeEls = () => <script dangerouslySetInnerHTML={{ __html: clientThemeCode }} />;
