import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { ErrorPage } from '~/components/error-page';
import { BadRequest, NotFound, Unauthorized } from '~/components/pages';

export const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 400:
        return <BadRequest error={error.data} />;
      case 401:
        return <Unauthorized />;
      case 404:
        return <NotFound />;
      default:
        return (
          <div>
            <h1>
              {error.status} {error.statusText}
            </h1>
            <p>{error.data}</p>
          </div>
        );
    }
  }

  if (error instanceof Error) {
    return <ErrorPage title={error.message} message={error.stack} />;
  }

  return <div>Error</div>;
};
