import { Link } from '@remix-run/react';
import { XCircle } from 'lucide-react';
import type { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { $path } from 'remix-routes';
import { Button } from '~/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '~/components/ui/card';

export const ErrorPage = ({ title, message }: { title: ReactNode; message: ReactNode }) => {
  return (
    <div className='flex min-h-screen items-center justify-center bg-background p-4'>
      <Card className='mx-auto w-full max-w-2xl border-destructive bg-destructive/5'>
        <CardHeader>
          <CardTitle className='flex items-center gap-2 text-destructive'>
            <XCircle className='h-5 w-5' />
            <Trans ns='page' i18nKey='error.title'>
              Server Error
            </Trans>
          </CardTitle>
        </CardHeader>

        <CardContent className='space-y-4'>
          <p className='font-semibold text-destructive'>{title}</p>
          <div className='max-h-60 overflow-auto rounded-md bg-muted p-4'>
            <pre className='whitespace-pre-wrap break-words font-mono text-xs'>{message}</pre>
          </div>
        </CardContent>

        <CardFooter>
          <Button variant='outline'>
            <Link to={$path('/')}>
              <Trans ns='page' i18nKey='button.dismiss'>
                Dismiss
              </Trans>
            </Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};
